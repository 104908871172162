import React from "react"
import Box from "@mui/material/Box"
import Copyright from "./Copyright"
const Footer = () => (
  <Box component="footer" sx={{ p: 2, bgcolor: "#eaeff1" }}>
    <Copyright />
  </Box>
)

export default Footer
