import React from "react"
import Card from "@mui/material/Card"
import CardActions from "@mui/material/CardActions"
import CardContent from "@mui/material/CardContent"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import formatDistance from 'date-fns/formatDistance'
import es from 'date-fns/locale/es'
import { useGetMLCacheStatQuery } from "../../services/fiammapi"

const ActualizaciónML = () => {
  const { data, isFetching, isLoading } = useGetMLCacheStatQuery()
  return (
    <Card sx={{ minWidth: 275, maxWidth: 360 }}>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          {
            (isLoading || isFetching)
            ? "..."
            :  (data) ? `Datos actualizados hace ${formatDistance(new Date(data.mtimeMs), new Date(), { locale: es })}`
            : 'No se pudo averiguar la fecha de actualización'
          }
        </Typography>
        <Typography variant="h5" component="div">
          Mercado Libre
        </Typography>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          ...
        </Typography>
        {/* <Typography sx={{ mb: 1.5 }} color="text.secondary">
          adjective
        </Typography>
        <Typography variant="body2">
          well meaning and kindly.
          <br />
          {'"a benevolent smile"'}
        </Typography> */}
      </CardContent>
      <CardActions>
        <Button size="small">ACTUALIZAR</Button>
      </CardActions>
    </Card>
  )
}

const Consolidation = () => {
  const serverUrl = process.env.REACT_APP_SERVER_URL
  return (
    <Box component="footer" sx={{ p: 2, m: 0, bgcolor: "#eaeff1", minHeight: "100vh" }}>
      <ActualizaciónML />
      <div>
        <a href={`${serverUrl}/api/fiammengo/consolidation/planilla`}> Generar planilla Excel para consolidar </a>
      </div>
    </Box>
  )
}
export default Consolidation
