import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
// import Button from "@mui/material/Button"
// import HelpIcon from "@mui/icons-material/Help"
// import IconButton from "@mui/material/IconButton"
import Toolbar from "@mui/material/Toolbar"
// import Tooltip from "@mui/material/Tooltip"
import Grid from "@mui/material/Grid"
import AppBar from "@mui/material/AppBar"
import React from "react"
import Tab from "@mui/material/Tab"
import Tabs from "@mui/material/Tabs"

export const lightColor = "rgba(255, 255, 255, 0.7)"

export const a11yProps = index => ({
  id: `simple-tab-${index}`,
  "aria-controls": `simple-tabpanel-${index}`,
})

export const TabPanel = ({ children, value, index, ...other }) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
    {...other}
  >
    {value === index && (
      <Box sx={{ p: 0 }}>
        {children}
      </Box>
    )}
  </div>
)
export const PageTitle = ({ title }) => (
  <AppBar component="div" color="primary" position="static" elevation={0} sx={{ zIndex: 0 }}>
    <Toolbar>
      <Grid container alignItems="center" spacing={1}>
        <Grid item xs>
          <Typography color="inherit" variant="h5" component="h1">
            {title}
          </Typography>
        </Grid>
      </Grid>
    </Toolbar>
  </AppBar>
)
export const PageTabs = ({ tabs }) => {
  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <React.Fragment>
      <AppBar component="div" position="static" elevation={0} sx={{ zIndex: 0 }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" textColor="inherit">
          {tabs.map((tab, index, tabs) => (
            <Tab key={index} label={tab.label} {...a11yProps(0)} />
          ))}
        </Tabs>
      </AppBar>
      <div>
        {tabs.map((tab, index, tabs) => (
          <TabPanel key={index} value={value} index={0}>
            {tab.element}
          </TabPanel>
        ))}
      </div>
    </React.Fragment>
  )
}
