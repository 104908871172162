import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import { Auth0ProviderWithHistory } from "./auth/auth0-provider-with.history"
import { Provider } from "react-redux"
import { BrowserRouter } from "react-router-dom"
import { store } from "./app/store"

ReactDOM.render(
  <BrowserRouter>
    <Auth0ProviderWithHistory>
      <Provider store={store}>
        <App />
      </Provider>
    </Auth0ProviderWithHistory>
  </BrowserRouter>,
  document.getElementById("root")
)
