import { configureStore } from "@reduxjs/toolkit"
import { setupListeners } from "@reduxjs/toolkit/query"
import { fiammapi } from "../services/fiammapi"
// import headerDataReducer from "../features/headerData/headerDataSlice"

export const store = configureStore({
  reducer: {
    // headerData: headerDataReducer,
    [fiammapi.reducerPath]: fiammapi.reducer,
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(fiammapi.middleware),
})

setupListeners(store.dispatch)
