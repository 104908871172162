import * as React from "react"
import { PageTitle, PageTabs, } from "../commons/page-helpers"
import UnitsOfMeasureList from "../features/uom/unitsOfMeasureList"

const tabs = [{label:"Unidades de medida", element:<UnitsOfMeasureList/>}]
const Database = () => {

  return (
    <React.Fragment>
      <PageTitle title={"Tablas de apoyo en la base de datos"} />
      <PageTabs tabs={tabs} />
    </React.Fragment>
  )
}

export default Database
