import * as React from "react"
import Divider from "@mui/material/Divider"
import Drawer from "@mui/material/Drawer"
import List from "@mui/material/List"
import Box from "@mui/material/Box"
import ListItem from "@mui/material/ListItem"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import HomeIcon from "@mui/icons-material/Home"
import { NavLink } from "react-router-dom"
import DnsRoundedIcon from "@mui/icons-material/DnsRounded"
import LayersIcon from "@mui/icons-material/Layers"
import FormatAlignJustifyIcon from "@mui/icons-material/FormatAlignJustify"
import PersonOutlineIcon from "@mui/icons-material/PersonOutline"
import MessageOutlinedIcon from "@mui/icons-material/MessageOutlined"
const categories = [
  {
    id: "Gestión comercial",
    children: [
      {
        id: "Existencias",
        icon: <LayersIcon />,
        active: true,
        to: "/existencias",
      },
      {
        id: "Stock",
        icon: <FormatAlignJustifyIcon />,
        active: true,
        to: "/stock",
      },
      { id: "Base de datos", icon: <DnsRoundedIcon />, to: "/data" },
      { id: "Mensajes", icon: <MessageOutlinedIcon />, to: "/messages" },
    ],
  },
  {
    id: "Administración interna",
    children: [
      { id: "Profile", icon: <PersonOutlineIcon />, to: "/profile" },
      // { id: "Analytics", icon: <SettingsIcon /> },
      // { id: "Performance", icon: <TimerIcon /> },
      // { id: "Test Lab", icon: <LayersIcon /> },
    ],
  },
]

const item = {
  py: "2px",
  px: 3,
  width: "100%",
  color: "rgba(255, 255, 255, 0.7)",
  "&:hover, &:focus": {
    bgcolor: "rgba(255, 255, 255, 0.08)",
  },
}

const itemCategory = {
  boxShadow: "0 -1px 0 rgb(255,255,255,0.1) inset",
  py: 1.5,
  px: 3,
}

const MyLink = ({ to, item, icon, childId }) => {
  return (
    <NavLink to={to}>
      {({ isActive }) => (
        <ListItemButton selected={isActive} sx={item}>
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText>{childId}</ListItemText>
        </ListItemButton>
      )}
    </NavLink>
  )
}

export default function Navigator(props) {
  const { ...other } = props

  return (
    <Drawer variant="permanent" {...other}>
      <List disablePadding>
        <ListItem sx={{ ...item, ...itemCategory, fontSize: 22, color: "#fff" }}>Fiamma</ListItem>
        <ListItem disablePadding sx={{ ...item, ...itemCategory, px:0 }}>
          {/* <NavLink to="/" sx={{ width: "100%" }}>
            <ListItemButton selected={true} sx={{ item, marginLeft: 0, paddingLeft: 0, width: "100%" }}>
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText>Inicio</ListItemText>
            </ListItemButton>
          </NavLink> */}
          <MyLink to="/" item={{ ...item, ...itemCategory }} icon={<HomeIcon />} childId="Inicio" />
        </ListItem>
        {categories.map(({ id, children }) => (
          // <Box key={id} sx={{ bgcolor: "#101F33" }}>
          <Box key={id}>
            <ListItem sx={{ py: 2, px: 3 }}>
              <ListItemText sx={{ color: "#fff" }}>{id}</ListItemText>
            </ListItem>
            {children.map(({ id: childId, icon, active, to }) => (
              <ListItem disablePadding key={childId}>
                {/* <NavLink to={to}>
                  <ListItemButton selected={false} sx={item}>
                    <ListItemIcon>{icon}</ListItemIcon>
                    <ListItemText>{childId}</ListItemText>
                  </ListItemButton>
                </NavLink> */}
                <MyLink to={to} item={item} icon={icon} childId={childId} />
              </ListItem>
            ))}
            <Divider sx={{ mt: 2 }} />
          </Box>
        ))}
      </List>
    </Drawer>
  )
}
