import * as React from "react"
import CssBaseline from "@mui/material/CssBaseline"
import Box from "@mui/material/Box"
import Navigator from "./components/Navigator"
import Header from "./components/Header"
import { useAuth0 } from "@auth0/auth0-react"
import { Footer, Loading } from "./components"
import MyTheme from "./components/MyTheme"
import { Routes, Route } from "react-router-dom"
import Home from "./pages/Home"
import Stock from "./pages/Stock"
import { Profile } from "./pages/profile"
import Database from "./pages/Database"
import ExternalApi from "./pages/ExternalApi"
const drawerWidth = 256

export default function Paperbase() {
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const { isLoading } = useAuth0()
  const isSmUp = true
  if (isLoading) {
    return <Loading />
  }
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const Navigators = () => (
    <Box component="nav" sx={{ width: { sm: drawerWidth }, flexShrink: { sm: -1 } }}>
      {isSmUp ? null : (
        <Navigator
          PaperProps={{ style: { width: drawerWidth } }}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
        />
      )}
      <Navigator PaperProps={{ style: { width: drawerWidth } }} sx={{ display: { sm: "block", xs: "none" } }} />
    </Box>
  )

  return (
    <MyTheme>
      <Box sx={{ display: "flex", minHeight: "100vh" }}>
        <CssBaseline />
        <Navigators />
        <Box sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
          <Header onDrawerToggle={handleDrawerToggle} />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/existencias" element={<Database />} />
            <Route path="/stock" element={<Stock />} />
            <Route path="/data" element={<Database />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/messages" element={<ExternalApi />} />
          </Routes>
          <Footer />
        </Box>
      </Box>
    </MyTheme>
  )
}
