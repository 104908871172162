import * as React from "react"
import Content from "../components/Content"
import { PageTitle, PageTabs } from "../commons/page-helpers"
import Box from "@mui/material/Box"

const tabs = [{ label: "Home content", element: <Content /> }]
const Home = () => {
  return (
    <Box>
      <PageTitle title={"Automatización. Productos para la industria. Servicios."} />
      <PageTabs tabs={tabs} />
    </Box>
  )
}

export default Home
