import * as React from "react"
import PropTypes from "prop-types"
import AppBar from "@mui/material/AppBar"
import Avatar from "@mui/material/Avatar"
import Grid from "@mui/material/Grid"
import MenuIcon from "@mui/icons-material/Menu"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import { useAuth0 } from "@auth0/auth0-react"
import AuthenticationButton from "./AuthenticationButton"

function Header(props) {
  const { user } = useAuth0()
  const picture = user ? user.picture : ""
  const { onDrawerToggle } = props

  return (
    <React.Fragment>
      <AppBar color="primary" position="sticky" elevation={0}>
        <Toolbar>
          <Grid container spacing={1} alignItems="center">
            <Grid sx={{ display: { sm: "none", xs: "block" } }} item>
              <div color="inherit" aria-label="open drawer" onClick={onDrawerToggle} edge="start">
                <MenuIcon />
              </div>
            </Grid>
            <Grid item xs>
              <Typography variant="h4" sx={{ mt: 1, mb: 0, pb: 0 }}>
                Fiammengo SRL
              </Typography>
            </Grid>

            <Grid item>
              <Grid container>
                <Avatar src={picture} alt="My Avatar" />
                <AuthenticationButton />
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  )
}

Header.propTypes = {
  onDrawerToggle: PropTypes.func.isRequired,
}

export default Header
