import * as React from "react"
// import { PayloadAction } from "@reduxjs/toolkit"
import Button from "@mui/material/Button"
import Box from "@mui/material/Box"
import TextField from "@mui/material/TextField"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import Switch from "@mui/material/Switch"
import DialogContent from "@mui/material/DialogContent"
import FormControlLabel from "@mui/material/FormControlLabel"
import DialogTitle from "@mui/material/DialogTitle"
import { IdName, useGetBasicUnitsQuery } from "../../services/fiammapi"
import { UnitOfMeasureType } from "../../types"
import Spinner from "../../components/Spinner"

const initialData: UnitOfMeasureType = {
  id: -1,
  key: "",
  name: "",
  equivalence: undefined,
  factor: undefined,
}
const formReducer = (state: UnitOfMeasureType, action: { name: string; value: string }) => {
  // if (action.payload.reset) {
  //   return initialData
  // }
  return {
    ...state,
    [action.name]: action.value,
  }
}

type UnitOfMeasureEditorPropsType = {
  open: boolean
  handleClose: () => void
  data: UnitOfMeasureType
}

export default function UnitOfMeasureEditor({ open, handleClose }: UnitOfMeasureEditorPropsType) {
  const [formData, setFormData] = React.useReducer(formReducer, initialData)
  const { data, isFetching, isLoading } = useGetBasicUnitsQuery()
  const [multiplo, setMultiplo] = React.useState(false)
  const [submitting, setSubmitting] = React.useState(false)

  const handleSubmit = async (event: any) => {
    event.preventDefault()
    setSubmitting(true)
    //await persistData(formData)
    setSubmitting(false)
    // setFormData({
    //   reset: true,
    // })
  }

  const basicUnits: IdName[] = (data && data[0]) ? data : [{id:0, name:"NoData"}]  

  const handleChange = (event: any) => {
    setFormData({
      name: event.target.name,
      value: event.target.type === "checkbox" ? event.target.checked : event.target.value,
    })
  }

  const toggleMultiplo = () => {
    setMultiplo(!multiplo)
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <Box sx={{ padding: 2 }}>
        {isLoading ? (
          <Spinner text="Loading..." />
        ) : isFetching ? (
          <Spinner text="Loading..." />
        ) : !data ? (
          <React.Fragment>{JSON.stringify(basicUnits)}</React.Fragment>
        ) : submitting ? (
          <Spinner text="Loading..." />
        ) : (
          <form onSubmit={handleSubmit}>
            <DialogTitle>Unidad de medida {formData.id === -1 ? "nueva" : formData.id}</DialogTitle>
            <div>{JSON.stringify(basicUnits)}</div>
            <DialogContent>
              {/* <DialogContentText>Ingresá o modificá los datos del formulario.</DialogContentText> */}
              <TextField
                autoFocus
                margin="dense"
                id="key"
                name="key"
                label="Clave de la unidad de medida"
                type="text"
                fullWidth
                variant="standard"
                onChange={handleChange}
                value={formData.key}
              />
              <TextField
                margin="dense"
                id="name"
                name="name"
                label="Nombre de la unidad de medida"
                type="text"
                fullWidth
                variant="standard"
                onChange={handleChange}
                value={formData.name}
              />
              <Box sx={{ margin: "24px 0 24px 0" }}>
                <FormControlLabel
                  labelPlacement="start"
                  control={<Switch defaultChecked />}
                  label="¿Es múltiplo o submúltiplo de una unidad de medida base?"
                  sx={{ ml: 0, mr: 0 }}
                  value={multiplo}
                  onChange={toggleMultiplo}
                />
              </Box>
              <TextField
                id="standard-select-currency-native"
                select
                label="Unidad base"
                value={basicUnits[0].id || "NoBase"}
                disabled={!multiplo}
                fullWidth
                helperText="."
                variant="standard"
                onChange={handleChange}
              >
                {Array.isArray(basicUnits) &&
                  basicUnits.map(option => (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  ))}
              </TextField>
              <TextField
                margin="dense"
                id="name"
                name="name"
                label="Factor de multiplicación de la base para llegar a esta unidad"
                type="text"
                disabled={!multiplo}
                fullWidth
                variant="standard"
                value={formData.name}
                onChange={handleChange}
              />
            </DialogContent>
            <DialogActions>
              <Button type="submit">Submit</Button>
              <Button onClick={handleClose}>Cancel</Button>
            </DialogActions>
          </form>
        )}
      </Box>
    </Dialog>
  )
}
