// src/components/logout-button.js

import React from "react"
import { useAuth0 } from "@auth0/auth0-react"
import Button from "@mui/material/Button"
const lightColor = "rgba(255, 255, 255, 0.7)"

const LogoutButton = () => {
  const { logout } = useAuth0()
  return (
    <Button
      sx={{ borderColor: lightColor, marginLeft: 1 }}
      variant="text"
      color="inherit"
      size="small"
      onClick={() => logout({ returnTo: window.location.origin })}
    >
      Salir
    </Button>
  )
}

export default LogoutButton
