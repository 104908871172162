// src/views/external-api.js

import React, { useState } from "react"
import { useAuth0 } from "@auth0/auth0-react"
import Box from "@mui/material/Box"
import { PageTitle, PageTabs } from "../commons/page-helpers"

const ExternalApi = () => {
  const [message, setMessage] = useState("")
  const { getAccessTokenSilently } = useAuth0()
  
  const serverUrl = process.env.REACT_APP_SERVER_URL
  console.log(serverUrl)


  const callApi = async () => {
    try {
      const response = await fetch(`${serverUrl}/api/messages/public-message`)

      const responseData = await response.json()

      setMessage(responseData.message)
    } catch (error) {
      setMessage(error.message)
    }
  }

  const callSecureApi = async () => {
    try {
      const token = await getAccessTokenSilently()
      //console.log({ token })
      const response = await fetch(`${serverUrl}/api/messages/protected-message`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

      const responseData = await response.json()

      setMessage(responseData.message)
    } catch (error) {
      setMessage(error.message)
    }
  }

  const Messages = () => (
    <Box component="footer" sx={{ p: 2, m: 0, bgcolor: "#eaeff1", minHeight: "100vh" }}>
      <div>
        <a href={`${serverUrl}/api/fiammengo/consolidation/planilla`}> Generar planilla Excel para consolidar </a>
      </div>
      <p>
        Use these buttons to call an external API. The protected API call has an access token in its authorization
        header. The API server will validate the access token using the Auth0 Audience value.
      </p>
      <div className="btn-group mt-5" role="group" aria-label="External API Requests Examples">
        <button type="button" className="btn btn-primary" onClick={callApi}>
          Get Public Message
        </button>
        <button type="button" className="btn btn-primary" onClick={callSecureApi}>
          Get Protected Message
        </button>
      </div>
      {message && (
        <div className="mt-5">
          <h6 className="muted">Result</h6>
          <div className="container-fluid">
            <div className="row">
              <code className="col-12 text-light bg-dark p-4">{message}</code>
            </div>
          </div>
        </div>
      )}
    </Box>
  )

  const tabs = [{ label: "External API", element: <Messages /> }]

  return (
    <React.Fragment>
      <PageTitle title={"External API"} />
      <PageTabs tabs={tabs} />
    </React.Fragment>
  )
}

export default ExternalApi
