import React from "react"
import { PageTitle, PageTabs } from "../commons/page-helpers"
import Consolidation from "../features/consolidation/Consolidation"

const tabs = [{ label: "Consolidación", element: <Consolidation /> }]

const Stock = () => {
  return (
    <React.Fragment>
      <PageTitle title={"Análisis de las existencias"} />
      <PageTabs tabs={tabs} />
    </React.Fragment>
  )
}

export default Stock
