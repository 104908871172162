import * as React from "react"
import { useGetUnitsOfMeasureQuery } from "../../services/fiammapi"
import Spinner from "../../components/Spinner"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import Divider from "@mui/material/Divider"
import ListItemText from "@mui/material/ListItemText"
import ListItemAvatar from "@mui/material/ListItemAvatar"
import Avatar from "@mui/material/Avatar"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import AppBar from "@mui/material/AppBar"
import Toolbar from "@mui/material/Toolbar"
import Paper from "@mui/material/Paper"
import Box from "@mui/material/Box"
// import TextField from "@mui/material/TextField"
import Tooltip from "@mui/material/Tooltip"
// import SearchIcon from "@mui/icons-material/Search"
// import RefreshIcon from "@mui/icons-material/Refresh"
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined"
import EditOutlinedIcon from "@mui/icons-material/EditOutlined"
import Fab from "@mui/material/Fab"
import Button from "@mui/material/Button"
import AddIcon from "@mui/icons-material/Add"
import UnitOfMeasureEditor from "./UnitOfMeasureEditor"

const UnitsOfMeasureList = () => {
  const { data, isFetching, isLoading } = useGetUnitsOfMeasureQuery()
  const [open, setOpen] = React.useState(false)

  if (isLoading) return <div>Loading...</div>
  if (isFetching) return <div>Fetching...</div>
  if (!data) return <div>Missing post!</div>

  const handleClickOpen = () => {
    setOpen(true)
  }
  const fabStyle = {
    position: "absolute",
    right: 52,
    top: 0,
  }

  const handleClose = () => {
    setOpen(false)
  }

  const textFromUM = um =>
    um.equivalence && um.equivalence !== null
      ? `${um.name}. Equivale a ${um.factor} ${um.referente?.toLowerCase()}`
      : `${um.name}.`
  return (
    <Box sx={{ m: 3 }}>
      <Grid container>
        <Paper sx={{ width: "90%", margin: "auto", overflow: "grow" }}>
          <AppBar
            position="static"
            color="default"
            elevation={0}
            sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)", height: 92 }}
          >
            <Toolbar sx={{ mt: "auto", mb: "auto" }}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={6}>
                  <Typography variant="h6" component="span" sx={{ pr: 3 }} color="#666">
                    Unidades de medida
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Tooltip title="Agregar una nueva unidad de medida">
                    <Fab color="primary" aria-label="add" size="large" onClick={handleClickOpen} sx={fabStyle}>
                      <AddIcon />
                    </Fab>
                  </Tooltip>
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
          {isLoading ? (
            <Spinner text="Loading..." />
          ) : isFetching ? (
            <Spinner text="Loading..." />
          ) : !data ? (
            <Box>
              <p>Mmmmm...Recibimos una lista vacía de unidades de medida....</p>
              <p>Estamos comunidados con el servidor de Fiamma. Pero no nos ha enviado ninguna unidad de medida....</p>
              <p>Mejor llamalo a Juan (y si sos Juan... estás en problemas)</p>
            </Box>
          ) : (
            <List sx={{ bgcolor: "background.paper" }}>
              {data.map(um => (
                <Box key={um.key}>
                  <ListItem
                    alignItems="flex-start"
                    secondaryAction={
                      <Box>
                        <Button edge="end" aria-label="edit">
                          <EditOutlinedIcon sx={{ color: "#808080" }} />
                        </Button>
                        <Button edge="end" aria-label="delete">
                          <DeleteOutlinedIcon sx={{ color: "#808080" }} />
                        </Button>
                      </Box>
                    }
                  >
                    <ListItemAvatar>
                      <Avatar alt={um.key} src="/static/images/avatar/1.jpg" />
                    </ListItemAvatar>
                    <ListItemText primary={um.key} secondary={textFromUM(um)} />
                  </ListItem>
                  <Divider variant="inset" component="li" />
                </Box>
              ))}
            </List>
          )}
        </Paper>

        <UnitOfMeasureEditor open={open} handleClose={handleClose} data={data} />
      </Grid>
    </Box>
  )
}
export default UnitsOfMeasureList
