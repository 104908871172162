// src/components/login-button.js

import React from "react"
import { useAuth0 } from "@auth0/auth0-react"
import Button from "@mui/material/Button"
const lightColor = "rgba(255, 255, 255, 0.7)"

const LoginButton = () => {
  const { loginWithRedirect } = useAuth0()
  return (
    <Button
      sx={{ borderColor: lightColor, marginLeft: 1 }}
      variant="text"
      color="inherit"
      size="small"
      onClick={() => loginWithRedirect()}
    >
      Ingresar
    </Button>
  )
}

export default LoginButton
